import React from "react";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  translate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import RohStatus from "../../config/RohStatus";
import CustomFilter from "../CustomFilter";
import UserAvatar from "../userDetails/UserAvatar";
import { NullableBooleanSelectOptions } from "../../config/NullableBooleanSelectOptions";
import AdditionFilter from "../customFields/filter/AdditionFilter";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
      overflow: "hidden",
    },
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "100%",
  },
  autocomplete: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
  //we override the Ra input styles, so the arrayinputs have the same height as the other inputs
  inputRoot: {
    flexWrap: "nowrap",
  },
  inputRootFilled: {
    flexWrap: "nowrap",
  },
}));

const UserDetailsAutosuggestItem = ({ record: { displayName, displayStyle, user, avatarUrl } }) => (
  <span
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    }}
  >
    <UserAvatar user={user} displayName={displayName} displayStyle={displayStyle} avatarUrl={avatarUrl} size={25} />
    <span style={{ marginLeft: 8 }}>{displayName}</span>
  </span>
);

const ShipmentFilters = ({ translate, ...props }) => {
  const classes = useStyles(props);

  return (
    <CustomFilter {...props} className={classes.container}>
      <TextInput
        source="order#rapidshopId"
        label={translate(getKey("rapidshopId", resources.ORDERS))}
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        source="rapidshopId"
        label={translate(getKey("rapidshopId", resources.SHIPMENTS))}
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        label={translate(getKey("numberPayment", resources.SHIPMENTS))}
        source="numberPayment"
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        source="order#customer#debitorNumber"
        label={translate(getKey("debitorNumber", resources.CUSTOMERS))}
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        source="order#customer#email"
        label={translate(getKey("email", resources.CUSTOMERS))}
        alwaysOn
        fullWidth
        type="email"
        resettable
      />
      <TextInput
        source="order#customer#prename"
        label={translate(getKey("prename", resources.CUSTOMERS))}
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        source="order#customer#lastname"
        label={translate(getKey("lastname", resources.CUSTOMERS))}
        alwaysOn
        fullWidth
        resettable
      />
      <TextInput
        source="order#customer#companyName"
        label={translate(getKey("companyName", resources.CUSTOMERS))}
        alwaysOn
        fullWidth
        resettable
      />
      <ReferenceArrayInput
        source="materials#id"
        label={translate(getKey("material", resources.MODELS))}
        reference={resources.MATERIALS}
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        alwaysOn
        fullWidth
        filterToQuery={searchText => ({ autocomplete: searchText })}
      >
        <AutocompleteArrayInput
          optionText={({ name, methodKey, methodName, method, layer_thickness, color, isNew }) =>
            `${isNew ? "* " : ""}${methodKey || methodName || method || "?"} (${name +
              (color ? " " + color : "") +
              (layer_thickness ? " " + layer_thickness : "")})`
          }
          optionValue="__id"
          fullWidth
          options={{ fullWidth: true }}
          translateChoice={false}
          resettable
          classes={{ ...classes, container: classes.autocomplete }}
        />
      </ReferenceArrayInput>
      <SelectLongArrayInput
        source="models#rohStatus"
        label={translate(getKey("rohStatus", resources.MODELS))}
        choices={Object.values(RohStatus).filter(it => it.filterable && it.modelStatus)}
        optionValue="apiValue"
        optionText={it => translate(it.translationKey || "")}
        translateChoice={false}
        fullWidth
        alwaysOn
        resettable
      />
      <ReferenceInput
        label={translate(getKey("assignee", resources.SHIPMENTS))}
        source="assignedUserDetails"
        reference={resources.USER_DETAILS}
        perPage={1000}
        filterToQuery={searchText => ({ displayName: searchText })}
        sort={{ field: "displayName", order: "ASC" }}
        alwaysOn
        fullWidth
        /**
         * values from a filtergraph are wrapped inside an array so we have to format them before inserting into a single autocomplete input
         */
        format={val => (Array.isArray(val) ? val[0] : val)}
      >
        <AutocompleteInput
          optionText={<UserDetailsAutosuggestItem />}
          optionValue="id"
          source="id"
          options={{ fullWidth: true, type: "search" }}
          matchSuggestion={(filter, { displayName }) => displayName.includes(filter)}
          inputText={({ displayName }) => displayName}
          translateChoice={false}
          resettable
        />
      </ReferenceInput>
      <AdditionFilter />
      <SelectInput
        /*
        we're forced to implement the nullableBooleanInput ourselves
        since the one given by react can't do fullWidth :)
         */
        choices={Object.values(NullableBooleanSelectOptions)}
        optionText="translation"
        optionValue="key"
        source="paymentComplete"
        label={translate(getKey("paymentComplete", resources.SHIPMENTS))}
        fullWidth
      />
    </CustomFilter>
  );
};

export default translate(ShipmentFilters);
