import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  usePermissionsOptimized,
  useTranslate,
} from "react-admin";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import resources from "../../config/resources";
import { getKey } from "../../lib/i18nUtils";
import CustomFilter from "../CustomFilter";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
      overflow: "hidden",
    },
  },

  autocomplete: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
  //we override the Ra input styles, so the arrayinputs have the same height as the other inputs
  inputRoot: {
    flexWrap: "nowrap",
  },
  inputRootFilled: {
    flexWrap: "nowrap",
  },
}));
const SupportedMaterialFilters = ({ ...props }) => {
  const classes = useStyles(props);
  const permissions = usePermissionsOptimized();
  const translate = useTranslate();

  return (
    <CustomFilter {...props} className={classes.container} permissions={permissions}>
      <ReferenceInput
        label={translate(getKey("contractor", resources.SUPPORTED_MATERIALS))}
        source="contractor"
        reference={resources.CONTRACTORS}
        alwaysOn={true}
        fullWidth={true}
      >
        <SelectInput optionText="contractorName" optionValue="id" />
      </ReferenceInput>
      <ReferenceArrayInput
        source="material#id"
        label={translate(getKey("material", resources.MODELS))}
        reference={resources.MATERIALS}
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        alwaysOn
        fullWidth
        filterToQuery={searchText => ({ autocomplete: searchText })}
      >
        <AutocompleteArrayInput
          optionText={({ name, methodKey, methodName, method, color, layer_thickness, isNew }) =>
            `${isNew ? "* " : ""}${methodKey || methodName || method || "?"} (${name +
              (color ? " " + color : "") +
              (layer_thickness ? " " + layer_thickness : "")})`
          }
          optionValue="__id"
          fullWidth
          options={{ fullWidth: true }}
          translateChoice={false}
          resettable
          classes={{ ...classes, container: classes.autocomplete }}
        />
      </ReferenceArrayInput>
      <ReferenceInput
        source="priceFormula"
        label={translate(getKey("priceFormula", resources.SUPPORTED_MATERIALS))}
        reference={resources.FORMULAS}
        alwaysOn={true}
        fullWidth={true}
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <NumberInput
        label={translate(getKey("productionTime", resources.SUPPORTED_MATERIALS))}
        source="productionTime"
        alwaysOn={true}
        fullWidth={true}
      />
    </CustomFilter>
  );
};

SupportedMaterialFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SupportedMaterialFilters;
