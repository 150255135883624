import React from "react";
import { getKey } from "../../lib/i18nUtils";
import resources from "../../config/resources";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  TextInput,
  translate,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import SelectLongArrayInput from "../../SelectLongArrayInput";
import RohStatus from "../../config/RohStatus";
import CustomFilter from "../CustomFilter";
import AdditionFilter from "../customFields/filter/AdditionFilter";

const styles = theme => ({
  container: {
    display: "grid !important",
    width: "100%",

    gridTemplateColumns: "repeat(18, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    gridColumnGap: `${theme.spacing(1)}px`,

    "& > *": {
      gridColumnEnd: "span 3",
      overflow: "hidden",
    },
  },
  button: {
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    width: "100%",
  },
  autocomplete: {
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
});

const ArchivedShipmentFilters = ({ translate, classes, permissions, ...props }) => (
  <CustomFilter {...props} className={classes.container} permissions={permissions}>
    <TextInput
      source="order#rapidshopId"
      label={translate(getKey("rapidshopId", resources.ORDERS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="rapidshopId"
      label={translate(getKey("rapidshopId", resources.ARCHIVED_SHIPMENTS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="numberPayment"
      label={translate(getKey("numberPayment", resources.SHIPMENTS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="order#customer#debitorNumber"
      label={translate(getKey("debitorNumber", resources.CUSTOMERS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="order#customer#email"
      label={translate(getKey("email", resources.CUSTOMERS))}
      alwaysOn
      fullWidth
      type="email"
      resettable
    />
    <TextInput
      source="order#customer#prename"
      label={translate(getKey("prename", resources.CUSTOMERS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="order#customer#lastname"
      label={translate(getKey("lastname", resources.CUSTOMERS))}
      alwaysOn
      fullWidth
      resettable
    />
    <TextInput
      source="order#customer#companyName"
      label={translate(getKey("companyName", resources.CUSTOMERS))}
      alwaysOn={true}
      fullWidth={true}
      resettable
    />
    <ReferenceArrayInput
      source="materials#id"
      label={translate(getKey("material", resources.ARCHIVED_MODELS))}
      reference={resources.MATERIALS}
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      alwaysOn
      fullWidth
      filterToQuery={searchText => ({ autocomplete: searchText })}
    >
      <AutocompleteArrayInput
        optionText={({ name, methodKey, methodName, method, layer_thickness, color, isNew }) =>
          `${isNew ? "* " : ""}${methodKey || methodName || method || "?"} (${name +
            (color ? " " + color : "") +
            (layer_thickness ? " " + layer_thickness : "")})`
        }
        optionValue="__id"
        fullWidth={true}
        options={{ fullWidth: true }}
        translateChoice={false}
        resettable
        classes={{ container: classes.autocomplete }}
        className={classes.autocomplete}
      />
    </ReferenceArrayInput>
    {/*<SelectArrayInput
      source="modelStatus#equals"
      label={translate(getKey("modelStatus", resources.ARCHIVED_SHIPMENTS))}
      choices={Object.values(DetailedShopStatus)}
      optionValue="apiKey"
      optionText={it => translate(it.translation || "")}
      translateChoice={false}
      fullWidth={true}
      alwaysOn={true}
      resettable
    />*/}
    <SelectLongArrayInput
      source="models#rohStatus"
      label={translate(getKey("rohStatus", resources.ARCHIVED_MODELS))}
      choices={Object.values(RohStatus).filter(it => it.filterable && it.modelStatus)}
      optionValue="apiValue"
      optionText={it => translate(it.translationKey || "")}
      translateChoice={false}
      fullWidth
      alwaysOn
      resettable
    />
    <ReferenceInput
      label={translate(getKey("assignee", resources.ARCHIVED_SHIPMENTS))}
      source="assignee#user#id"
      reference={resources.USER_DETAILS}
      perPage={1000}
      filterToQuery={searchText => ({ displayName: searchText })}
      sort={{ field: "displayName", order: "ASC" }}
      alwaysOn
      fullWidth
      /**
       * values from a filtergraph are wrapped inside an array so we have to format them before inserting into a single autocomplete input
       */
      format={val => (Array.isArray(val) ? val[0] : val)}
    >
      <AutocompleteInput optionText="displayName" optionValue="user" allowEmpty />
    </ReferenceInput>
    <AdditionFilter />
  </CustomFilter>
);

export default withStyles(styles)(translate(ArchivedShipmentFilters));
